.login {
  min-height: 100vh;
  background: url('../../assets/img/login-bg.png') center center / cover;
}

/*font-family: 'Open Sans', sans-serif;*/

.login__form-wrap {
  width: 100%;
  max-width: 430px;
  background: #fff;
  border-radius: 25px;
  margin: 0 20px;
}

.login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login__form label {
  margin-top: 0;
  font-weight: 700;
}

.login__title {
  font-weight: 700;
  font-size: 18px;
  color: #000;
  margin-top: 32px;
}

.login__input {
  width: 100%;
  max-width: 256px;
  margin-top: 0 !important;
}

.login__button-wrap {
  margin-top: 44px;
}

.login__button-wrap .login__button:first-child {
  border: 1px solid #757575;
  color: #757575;
  background: #fff;
}

.login__button-wrap .login__button {
  border-radius: 50px;
  font-size: 14px;
  margin-right: 20px;
  text-transform: capitalize;
  box-shadow: none !important;
  font-weight: 700;
  width: 110px;
}

.login__button-wrap .login__button:last-child {
  margin-right: 0;
  background: #1A6D12;
  color: #fff;
}

.login__button-wrap .login__button--disable {
  color: #fff !important;
  background: #A3A3A3 !important;
}

.login__language {
  width: 100%;
  max-width: 430px;
  display: flex;
  justify-content: space-around;
  margin-top: 27px;
}

.login__language-button {
  font-weight: 700;
  background: transparent;
  border: none;
  color: #fff;
  font-family: 'Nunito', sans-serif !important;
  text-decoration: underline;
  font-size: 20px;
  outline: none;
  cursor: pointer             ;
}

.login__language-button--active {
  text-decoration: none;
}
